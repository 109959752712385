import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { ChakraProvider } from "@chakra-ui/react";

import "./styles/style.css";
import theme from "./theme";

import Main from "./Main";

import { tryLoadAndStartRecorder } from "@alwaysmeticulous/recorder-loader";

async function startApp() {
  // Record all sessions on localhost, staging stacks and preview URLs
  if (!isProduction()) {
    // Start the Meticulous recorder before you initialise your app.
    // Note: all errors are caught and logged, so no need to surround with try/catch
    await tryLoadAndStartRecorder({
      recordingToken: "dHtoaR2oC7n5rZQcErNH9ePmjalmOFbhlu3OuywZ",
      isProduction: false,
    });
  }

  await import("./i18n");

  ReactDOM.render(
    <Suspense fallback={<div>Loading...</div>}>
      <ChakraProvider theme={theme}>
        <Main />
      </ChakraProvider>
    </Suspense>,
    document.getElementById("root")
  );
}

function isProduction() {
  // TODO: Update me with your production hostname
  return window.location.hostname.indexOf("your-production-site.com") > -1;
}

startApp();
