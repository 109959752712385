import React from "react";
import { Button, ModalFooter, Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useSignup from "./useSignup";

export const ForgotPasswordStage = ({ email, onBack, apiUrl }) => {
  const { t } = useTranslation();
  const { forgotPassword, loading, error, success } = useSignup(apiUrl);

  const handleForgotPassword = async () => {
    await forgotPassword(email);
  };

  return (
    <>
      <Flex direction="column" gap={4}>
        <Text>{t("label.forgotPasswordInstructions")}</Text>
        {success && (
          <>
            <Text>{t("label.forgotPasswordSuccess")}</Text>
            <Text>{t("label.e-mail can take up to 5 minutes.")}</Text>
          </>
        )}
        {error && <Text color="red.500">{error}</Text>}
      </Flex>
      <ModalFooter justifyContent="space-between">
        <Button colorScheme="purple" onClick={onBack}>
          {t("button.Back")}
        </Button>
        <Button
          colorScheme="orange"
          onClick={handleForgotPassword}
          isLoading={loading}
        >
          {t("button.sendResetEmail")}
        </Button>
        <Button
          colorScheme="green"
          onClick={onBack.bind(null, "login")}
          isDisabled={!success}
        >
          {t("button.Login")}
        </Button>
      </ModalFooter>
    </>
  );
};
