import React from "react";
import RegistrationModel from "./RegistrationModel";
import MailingListModel from "./MailingListModel";

function Main() {
  const apiUrl = window.location.href.includes("localhost")
    ? "http://localhost:3001"
    : "https://mycalibrite-adhbhpdce3ffbzfa.uksouth-01.azurewebsites.net";

  if (window.location.href.includes("mailing")) {
    return (
      <MailingListModel isOpen={true} onClose={() => {}} apiUrl={apiUrl} />
    );
  } else {
    return (
      <RegistrationModel isOpen={true} onClose={() => {}} apiUrl={apiUrl} />
    );
  }
}

export default Main;
