import { useState } from 'react';

const useSignup = (domain) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const standardSignup = async (userData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch(domain + '/account/signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                throw new Error('Failed to register user');
            }

            const data = await response.json();

            setSuccess(true);
            setLoading(false);

            return data
        } catch (err) {
            setError(err.message);
            setLoading(false);

            return false
        }
    };

    const marketingUpgradeSignup = async (userData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        delete userData.contactConsent;
        delete userData.country;

        try {
            const response = await fetch(domain + '/account/signup/upgrade', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                throw new Error('Failed to register user');
            }

            const data = await response.json();

            setSuccess(true);
            setLoading(false);

            return data
        } catch (err) {
            setError(err.message);
            setLoading(false);

            return false
        }
    };

    const marketingSignup = async (userData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch(domain + '/account/signup/newsletter', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                throw new Error('Failed to register user');
            }

            const data = await response.json();

            setSuccess(true);
            setLoading(false);

            return data
        } catch (err) {
            setError(err.message);
            setLoading(false);

            return false
        }
    }

    const signup = (userData, isMarketingSignup, newsletter) => {
        if (newsletter) {
            return marketingSignup(userData)
        } else if (isMarketingSignup) {
            return marketingUpgradeSignup(userData)
        } else {
            return standardSignup(userData)
        }
    }

    const resetPassword = async (token, newPassword) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch(domain + '/api/users/reset-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, password: newPassword }),
            });

            if (!response.ok) {
                throw new Error('Failed to reset password');
            }

            const data = await response.json();

            setSuccess(true);
            setLoading(false);

            return data;
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return false;
        }
    };

    const forgotPassword = async (email) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch(domain + '/api/users/forgot-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to send password reset email');
            }

            setSuccess(true);
            setLoading(false);
            return true;
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return false;
        }
    };

    const verifyAccount = async (token) => {
        try {
            setLoading(true);
            setError(null);
            setSuccess(false);

            const response = await fetch(`${domain}/api/users/verify/${token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data = await response.json()

            if (data.message === "Email verified successfully.") {
                setSuccess(true);
                setLoading(false);

                return true
            } else {
                setLoading(false);
                setError(data.message);

                return false
            }
        } catch (error) {
            setLoading(false)
            setSuccess(false)
            setError(error.message)

            console.error("Error verifying account:", error);
            return false;
        }
    }

    return { signup, loading, error, success, resetPassword, forgotPassword, verifyAccount };
};

export default useSignup;
