import { useTranslation } from "react-i18next";
import { Button, ModalFooter } from "@chakra-ui/react";

const MaintenanceDialog = ({ isOpen, onClose, handleCompleteSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: t("otherHtml.Maintenance Dialog") }}
      ></div>
      <ModalFooter>
        <Button mt={4} colorScheme="green" onClick={handleCompleteSubmit}>
          {t("button.Continue")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default MaintenanceDialog;
