import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Flex,
  Input,
  Box,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import useCheckEmailVerification from "./useCheckEmailVerification";
import useLogin from "./useLogin";
import useRefreshJWT from "./useRefreshJWT";
import { color } from "./constants/color";
import { softwareLanguages } from "./constants/data";
import { useOnlineStatus } from "./useOnlineStatus";

import BarStrip from "./BarStrip";
import LanguageSelector from "./LanguageSelector";
import calibriteLogo from "./assets/Calibrite-colour-circle_greybg.svg";
import { EmailInputStage } from "./EmailInputStage";
import { PasswordStage } from "./PasswordStage";
import { DetailsStage } from "./DetailsStage";
import { VerificationPendingStage } from "./VerificationPendingStage";
import { RegistrationCompleteStage } from "./RegistrationCompleteStage";
import { LoginStage } from "./LoginStage";
import { ForgotPasswordStage } from "./ForgotPasswordStage";
import { ResetPasswordStage } from "./ResetPasswordStage";
import { VerificationLinkStage } from "./VerificationLinkStage";
import MaintenanceDialog from "./MaintenanceDialog";

export const openLink = (url) => {
  window.open(url, "_blank");
};

const RegistrationModel = ({ isOpen, onClose, setLoginStatus, apiUrl }) => {
  //dev
  // If hash includes delete show developer tool to delete user using /account/developer-delete-user api
  const [showDeleteTool, setShowDeleteTool] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState("");

  useEffect(() => {
    if (window.location.hash.includes("delete")) {
      setShowDeleteTool(true);
    }
  }, [setShowDeleteTool]);

  const handleDeleteUser = async () => {
    const email = localStorage.getItem("email");
    if (!email) {
      console.error("No email found in localStorage");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/account/developer-delete-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        console.log("User deleted successfully");
        // Clear local storage and reset state
        localStorage.clear();
        setEmail("");
        setStage("email-input");
      } else {
        console.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  //dev

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [stage, setStage] = useState("email-input");
  const [hasCheckedToken, setHasCheckedToken] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const { checkEmailVerification } = useCheckEmailVerification(apiUrl);
  const { refreshToken } = useRefreshJWT(apiUrl, "users");
  const { login } = useLogin(apiUrl);
  const online = useOnlineStatus();

  // Stage 1: Email Input
  // Stage 2: (Register): Personal Info
  // Stage 3: (Register): Registration Complete
  // Stage 2: (Login): Login

  const refreshAndValidateToken = useCallback(async () => {
    const refreshResult = await refreshToken();

    if (refreshResult) {
      const { token, exp } = refreshResult;
      const currentTime = Date.now() / 1000;

      if (exp > currentTime) {
        setStage("registration-complete");
        localStorage.setItem("payloadToken", token);
      } else {
        clearLocalStorage();
      }
    } else {
      const currentToken = localStorage.getItem("payloadToken");
      const parsedToken = jwtDecode(currentToken);

      if (parsedToken && parsedToken.exp * 1000 >= Date.now()) {
        setStage("registration-complete");
      } else {
        clearLocalStorage();
      }
    }

    setHasCheckedToken(true);
  }, [refreshToken]);

  const clearLocalStorage = () => {
    localStorage.removeItem("payloadToken");
    localStorage.removeItem("verificationStatus");
    localStorage.removeItem("emailExists");
    localStorage.removeItem("consecutiveLoginFailures");
  };

  const handleEmailSubmit = useCallback(
    async (submittedEmail, emailExists, isMarketingSignup) => {
      setEmail(submittedEmail);

      localStorage.removeItem("consecutiveLoginFailures");
      localStorage.setItem("emailExists", emailExists);
      localStorage.setItem("isMarketingSignup", isMarketingSignup);

      if (emailExists && !isMarketingSignup) {
        const isVerified = await checkEmailVerification(submittedEmail);
        if (isVerified) {
          localStorage.setItem("verificationStatus", "verified");
          setStage("login");
        } else {
          localStorage.setItem("verificationStatus", "pending");
          setStage("verification-pending");
        }
      } else {
        setStage("password");
      }
    },
    [checkEmailVerification]
  );

  const handleLoginBypass = useCallback(() => {
    // Limit of 3 consecutive login failures
    const consecutiveLoginFailures =
      localStorage.getItem("consecutiveLoginFailures") || 0;
    if (consecutiveLoginFailures < 5) {
      localStorage.setItem(
        "consecutiveLoginFailures",
        consecutiveLoginFailures + 1
      );
      setStage("maintenance");
    }
  }, []);

  const handlePasswordSubmit = useCallback((submittedPassword) => {
    setPassword(submittedPassword);
    setStage("details");
  }, []);

  const handleDetailsSubmit = useCallback(
    (password) => {
      clearLocalStorage();
      console.log("Registration data submitted", { email });
      setStage("verification-pending");
      setPassword(password);
    },
    [email]
  );

  const handleVerified = useCallback(async () => {
    localStorage.setItem("verificationStatus", "verified");
    const emailOptIn = localStorage.getItem("emailOptIn");

    if (password) {
      const result = await login(email, password, emailOptIn);
      if (result) {
        setStage("registration-complete");
        return;
      }
    }

    setStage("login");
  }, [email, password, login]);

  const handleCompleteSubmit = (data) => {
    onClose();
    setLoginStatus(true);
  };

  const handleLoginSubmit = (user, token) => {
    localStorage.setItem("payloadToken", token);
    setStage("registration-complete");
  };

  const handleBack = useCallback((to) => {
    setStage(typeof to === "string" ? to : "email-input");
    clearLocalStorage();
  }, []);

  const handleClearCache = useCallback(() => {
    clearLocalStorage();
    setStage("email-input");
    setEmail("");
    setPassword("");
  }, []);

  const handleForgotPassword = () => {
    setStage("forgot-password");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      setAuthToken(token);

      if (window.location.href.includes("reset-password")) {
        setStage("reset-password");
      } else if (window.location.href.includes("verify")) {
        setStage("verification-link");
      }
    }
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const keepSignedIn = localStorage.getItem("keepSignedIn");

    if (storedEmail && keepSignedIn === "true") {
      setEmail(storedEmail);
    } else {
      if (window.location.protocol !== "file:") {
        // Clear cache if 'Keep me signed in' was not checked
        clearLocalStorage();
      }
    }

    const token = localStorage.getItem("payloadToken");
    const verificationStatus = localStorage.getItem("verificationStatus");

    if (token && verificationStatus === "verified") {
      refreshAndValidateToken();
    } else {
      setHasCheckedToken(true);
    }
  }, [refreshAndValidateToken]);

  //dev
  if (showDeleteTool) {
    return (
      <Box>
        <Text>Delete User Tool</Text>
        <Input
          placeholder="Email"
          value={emailToDelete}
          onChange={(e) => setEmailToDelete(e.target.value)}
        />
        <Button onClick={handleDeleteUser}>Delete User</Button>
      </Box>
    );
  }
  //dev

  if (!hasCheckedToken) {
    return null;
  }

  if (!online && stage !== "registration-complete") {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor={color.grey}
          color={color.lightWhite}
          outline="0.063rem solid white"
        >
          <ModalHeader>
            <Flex justifyContent={"space-between"}>
              <Image width={"40px"} mb={2} src={calibriteLogo} />
              <LanguageSelector languages={softwareLanguages} />
            </Flex>
            <BarStrip />
            <Text>{t("heading.Offline Notification")}</Text>
          </ModalHeader>
          <Box m={4}>
            <div
              dangerouslySetInnerHTML={{
                __html: t("otherHtml.Offline Registration"),
              }}
            ></div>
          </Box>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => {
                window.electronAPI.quit();
              }}
              mr={3}
            >
              {t("button.Quit")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor={color.grey}
        color={color.lightWhite}
        outline="0.063rem solid white"
      >
        <ModalHeader>
          <Flex justifyContent={"space-between"}>
            <Image width={"40px"} mb={2} src={calibriteLogo} />
            <LanguageSelector languages={softwareLanguages} />
          </Flex>
          <BarStrip />
          {stage === "registration-complete"
            ? t("heading.Home Screen / End")
            : stage === "login"
            ? t("heading.Log-in Calibrite User Account")
            : stage === "forgot-password"
            ? t("heading.Forgot Password")
            : stage === "password" || stage === "details"
            ? t("heading.Create Calibrite User Account")
            : stage === "reset-password"
            ? t("heading.Reset Password")
            : stage === "verification-link"
            ? t("heading.Verify Your Account")
            : t("heading.Log-in / Create Calibrite User Account")}
        </ModalHeader>
        <ModalBody>
          {stage === "email-input" && (
            <EmailInputStage
              onNext={handleEmailSubmit}
              apiUrl={apiUrl}
              bypassLogin={handleLoginBypass}
            />
          )}
          {stage === "password" && (
            <PasswordStage
              onSubmit={handlePasswordSubmit}
              email={email}
              onBack={handleBack}
              apiUrl={apiUrl}
            />
          )}
          {stage === "details" && (
            <DetailsStage
              onSubmit={handleDetailsSubmit}
              email={email}
              password={password}
              onBack={() => setStage("password")}
              apiUrl={apiUrl}
            />
          )}
          {stage === "verification-pending" && (
            <VerificationPendingStage
              email={email}
              onVerified={handleVerified}
              apiUrl={apiUrl}
            />
          )}
          {stage === "login" && (
            <LoginStage
              onSubmit={handleLoginSubmit}
              email={email}
              onBack={handleBack}
              handleForgotPassword={handleForgotPassword}
              apiUrl={apiUrl}
            />
          )}
          {stage === "forgot-password" && (
            <ForgotPasswordStage
              email={email}
              onBack={handleBack}
              apiUrl={apiUrl}
            />
          )}
          {stage === "reset-password" && (
            <ResetPasswordStage token={authToken} apiUrl={apiUrl} />
          )}
          {stage === "registration-complete" && (
            <RegistrationCompleteStage
              email={email}
              onSubmit={handleCompleteSubmit}
              onClearCache={handleClearCache}
              handleCompleteSubmit={handleCompleteSubmit}
              apiUrl={apiUrl}
            />
          )}
          {stage === "verification-link" && (
            <VerificationLinkStage token={authToken} apiUrl={apiUrl} />
          )}
          {stage === "maintenance" && (
            <MaintenanceDialog handleCompleteSubmit={handleCompleteSubmit} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RegistrationModel;
