import React, { useCallback, useEffect } from "react";
import { Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useCheckEmailVerification from "./useCheckEmailVerification";

export const VerificationPendingStage = ({ email, onVerified, apiUrl }) => {
  const { t } = useTranslation();
  const { checkEmailVerification, loading, error } =
    useCheckEmailVerification(apiUrl);

  const checkVerification = useCallback(async () => {
    const isVerified = await checkEmailVerification(email);
    if (isVerified) {
      onVerified();
    }
  }, [email, checkEmailVerification, onVerified]);

  useEffect(() => {
    const interval = setInterval(checkVerification, 15 * 1000);
    return () => clearInterval(interval);
  }, [checkVerification]);

  return (
    <>
      <Flex direction="column" gap={4}>
        <Text fontWeight="bold">{t("label.Awaiting Validation")}</Text>
        <Text>
          {t("label.verificationInstructions").replace(
            "{{xxx@xxxx.xx}}",
            email
          )}
        </Text>
        <Text>
          {t(
            "label.If the email address is incorrect please exit and re-register"
          )}
        </Text>
        <Text>
          {t(
            "label.Please check your inbox including your spam folder for the validation link"
          )}
        </Text>
        <Text>
          {t("label.It may take up to 5 minutes for the email to arrive.")}
        </Text>
        <Text>{t("label.Once you receive it click the link to proceed")}</Text>
        {error && <Text color="red.500">{error}</Text>}
        {loading && <Text>{t("label.checkingVerification")}</Text>}
      </Flex>
    </>
  );
};
