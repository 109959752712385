import colorStrip from "./assets/Calibrite-colour-strip.svg";

const BarStrip = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "0.625rem",
        backgroundImage: `url(${colorStrip})`,
        backgroundSize: "18.125rem",
      }}
    ></div>
  );
};

export default BarStrip;
